import { React, useState } from 'react';
import { tokens } from "../theme"
import { useTheme } from '@mui/material';
import '../components/visuals/design/sk.css'

// import SnHighlights from '../components/SN/SnHighlights';
import SNTopSellers from '../components/SN/SNTopSellers';

const Highlights = () => {

    console.log('hello sk')

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: "10px" }}>

            <div >
                <SNTopSellers />
            </div>

        </div >
    )
}

export default Highlights

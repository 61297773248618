import React, { useEffect, useState } from 'react';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material';
import '../../components/visuals/design/Nov11.css';


const Nov11 = () => {
    const theme = useTheme();

    // Determine the opposite theme mode
    const isDarkMode = theme.palette.mode === 'dark';
    const oppositeMode = isDarkMode ? 'light' : 'dark';

    // Get color tokens based on the opposite mode
    const colors = tokens(oppositeMode);

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [editMode, setEditMode] = useState({});
    const [newProduct, setNewProduct] = useState({
        BRAND: '',
        PRODUCT: '',
        QTY: '',
        STATUS: 'city harvest',
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrys2`);
                if (!response.ok) throw new Error("Failed to fetch data");
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        const fetchData1 = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrys2/1`);
                if (!response.ok) throw new Error("Failed to fetch data");
                const result = await response.json();
                setData1(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        fetchData1();
    }, []);


    // Function to handle status change
    const handleStatusChange = async (productId, newStatus) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrys2/updateStatus`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: productId, status: newStatus }),
            });

            if (response.ok) {
                const updatedProduct = await response.json();

                // Update the state for data
                setData(prevData =>
                    prevData.map(item =>
                        item._id === productId ? { ...item, STATUS: newStatus } : item
                    )
                );

                // Update the state for data1
                setData1(prevData1 =>
                    prevData1.map(item =>
                        item._id === productId ? { ...item, STATUS: newStatus } : item
                    )
                );

                console.log("Status updated in state:", newStatus);
            } else {
                console.error("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const handleArrivedChange = async (productId, arrivedStatus) => {
        try {
            // Make a PATCH request to update the ARRIVED status in the database
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrys2/arrival`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: productId, arrived: arrivedStatus }),
            });

            if (response.ok) {
                // Update the state for `data` and `data1` if the request succeeds
                setData(prevData =>
                    prevData.map(item =>
                        item._id === productId ? { ...item, ARRIVED: arrivedStatus } : item
                    )
                );

                setData1(prevData1 =>
                    prevData1.map(item =>
                        item._id === productId ? { ...item, ARRIVED: arrivedStatus } : item
                    )
                );

                console.log(`ARRIVED status updated for product ${productId}:`, arrivedStatus);
            } else {
                console.error("Failed to update ARRIVED status");
            }
        } catch (error) {
            console.error("Error updating ARRIVED status:", error);
        }
    };

    // Group products by brand and sort alphabetically
    const brands = [...new Set(data.map(item => item.BRAND))].sort();
    const brands1 = [...new Set(data1.map(item => item.BRAND))].sort();

    const toggleEditMode = (id) => {
        setEditMode((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    // Handle form input change
    const handleInputChange = (id, field, value) => {
        setData((prevData) =>
            prevData.map((item) =>
                item._id === id ? { ...item, [field]: value } : item
            )
        );
    };

    const handleSaveChanges = async (product) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrys2/edit`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(product),
            });

            if (response.ok) {
                console.log("Changes saved successfully!");
                setEditMode((prev) => ({ ...prev, [product._id]: false }));
            } else {
                console.error("Failed to save changes.");
            }
        } catch (error) {
            console.error("Error saving changes:", error);
        }
    };
    // Function to add a new product
    const addNewProduct = async () => {
        const productData = {
            ...newProduct,
            GROUP: 2,
            ARRIVED: false,
        };

        console.log("Sending product data:", productData);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrys2/new`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productData),
            });

            if (response.ok) {
                const addedProduct = await response.json();
                console.log("Product added successfully:", addedProduct); // Debugging log

                window.location.reload();

            } else {
                console.error("Failed to add new product. Status:", response.status);
            }
        } catch (error) {
            console.error("Error adding new product:", error);
        }
    };

    // Toggle pop-up visibility
    const togglePopup = () => setShowPopup(!showPopup);



    return (
        <div
            className="nov11-container"
            style={{
                backgroundColor: colors.primary[500],
                color: colors.SKcustom[20],
            }}
        >

            <p>Click on brand for list of items.</p>

            <div className="rowPlain">
                <button style={{ width: '40px' }} className="add-button" onClick={togglePopup}>+</button>
                <p>Add new</p>
            </div>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h3 style={{ color: '#000' }}>Add New Product</h3>
                        <form>
                            <div className="form-group">
                                <label>Brand:</label>
                                <input
                                    type="text"
                                    name="BRAND"
                                    value={newProduct.BRAND}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Product:</label>
                                <input
                                    type="text"
                                    name="PRODUCT"
                                    value={newProduct.PRODUCT}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Quantity:</label>
                                <input
                                    type="number"
                                    name="QTY"
                                    value={newProduct.QTY}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Status:</label>
                                <select
                                    name="STATUS"
                                    value={newProduct.STATUS}
                                    onChange={handleInputChange}
                                >
                                    <option value="city harvest">City Harvest</option>
                                    <option value="office">Office</option>
                                    <option value="laura">Laura</option>
                                </select>
                            </div>

                            <div className="form-buttons">
                                <button type="button" onClick={addNewProduct}>
                                    Add Product
                                </button>
                                <button type="button" onClick={togglePopup}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}



            {brands.map(brand => (
                <div key={brand} className="brand-section">
                    <h2 style={{ color: colors.SKcustom[10] }} onClick={() => setSelectedBrand(selectedBrand === brand ? null : brand)}>
                        {brand}
                    </h2>

                    {selectedBrand === brand && (
                        <div className="products-list" style={{ color: colors.SKcustom[10] }}>
                            {data
                                .filter(product => product.BRAND === brand)
                                .map(product => (

                                    <div key={product._id} className="product-item">
                                        <div className="product-info">
                                            {!editMode[product._id] ? (
                                                <>
                                                    {/* Read-only mode */}
                                                    <span className="product-name">Brand: {product.BRAND}</span>
                                                    <span className="product-name">Product: {product.PRODUCT}</span>
                                                    <span className="product-qty">Qty: {product.QTY}</span>
                                                    <span className="product-status">Status: {product.STATUS}</span>
                                                    <span className="arrived-status">
                                                        Arrived: {product.ARRIVED ? "Yes" : "No"}
                                                    </span>
                                                    <button onClick={() => toggleEditMode(product._id)}>Edit</button>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Edit mode */}
                                                    <input
                                                        type="text"
                                                        value={product.BRAND}
                                                        onChange={(e) => handleInputChange(product._id, 'BRAND', e.target.value)}
                                                        placeholder="Brand"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={product.PRODUCT}
                                                        onChange={(e) => handleInputChange(product._id, 'PRODUCT', e.target.value)}
                                                        placeholder="Product"
                                                    />
                                                    <input
                                                        type="number"
                                                        value={product.QTY}
                                                        onChange={(e) => handleInputChange(product._id, 'QTY', e.target.value)}
                                                        placeholder="Quantity"
                                                    />
                                                    <select
                                                        value={product.STATUS}
                                                        onChange={(e) => handleInputChange(product._id, 'STATUS', e.target.value)}
                                                    >
                                                        <option value="city harvest">City Harvest</option>
                                                        <option value="office">Office</option>
                                                        <option value="laura">Laura</option>
                                                        <option value="?">?</option>
                                                    </select>
                                                    <label className="arrived-label">
                                                        <input
                                                            type="checkbox"
                                                            checked={product.ARRIVED || false}
                                                            onChange={(e) => handleInputChange(product._id, 'ARRIVED', e.target.checked)}
                                                        />
                                                        Arrived
                                                    </label>
                                                    <button onClick={() => handleSaveChanges(product)}>Save</button>
                                                    <button onClick={() => toggleEditMode(product._id)}>Cancel</button>
                                                </>
                                            )}
                                        </div>
                                        <button onClick={() => handleSaveChanges(product)}>Save</button>


                                        <div className="product-status">
                                            {['office', 'city harvest', 'laura', '?'].includes(product.STATUS) && (
                                                <div className="status-options">
                                                    {['?', 'office', 'city harvest', 'laura'].map(status => (
                                                        <label key={`${product._id}-${status}`} className="status-label">
                                                            <input
                                                                type="radio"
                                                                name={`status-${product._id}`}
                                                                value={status}
                                                                checked={product.STATUS === status}
                                                                onChange={(e) => handleStatusChange(product._id, e.target.value)}
                                                            />
                                                            {status}
                                                        </label>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <div className="product-status">
                                            <label className="arrived-label" style={{ color: '#FF0800' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={product?.ARRIVED || false} // Ensure `product` is defined
                                                    onChange={(e) => handleArrivedChange(product._id, e.target.checked)}
                                                />
                                                Arrived
                                            </label>
                                        </div>

                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            ))}


            <div style={{}}>
                <p style={{ fontSize: '16px', fontWeight: 800, color: "#ee9568" }}>Items from part 1 of S02</p>
            </div>


            {brands1.map(brand => (
                <div key={brand} className="brand-section">
                    <h2 style={{ color: colors.SKcustom[10] }} onClick={() => setSelectedBrand(selectedBrand === brand ? null : brand)}>
                        {brand}
                    </h2>

                    {selectedBrand === brand && (
                        <div className="products-list" style={{ color: colors.SKcustom[10] }}>
                            {data1
                                .filter(product => product.BRAND === brand)
                                .map(product => (

                                    <div key={product._id} className="product-item">
                                        <div className="product-info">
                                            <span className="product-name">{product.PRODUCT}</span>
                                            <span className="product-qty">Qty: {product.QTY}</span>
                                        </div>


                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Nov11;

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");


    const handleLogin = async (e) => {
        e.preventDefault();
        const data = {
            username: username,
            password: password
        }
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        );

        const res = await response.json();
        console.log(res);

        if (res.message === 'Email not found') {
            console.log('user not found');
            setErrorMessage('Invalid credentials, please try again.')
            // Show the user that their username/password is incorrect
        } else {
            localStorage.setItem('token', res.token)

            props.setUser(res.user)
            navigate('/')
        }
    };
    return (
        <div style={{ margin: "1em" }}>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <form onSubmit={(e) => handleLogin(e)}>
                    <div style={{ marginBottom: "1em", color: "#ab966d" }}>
                        <label htmlFor="username">Username:   </label>
                        <input //attributes below
                            type="text"
                            id="username"
                            autoComplete="off"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        /></div>

                    <div style={{ marginBottom: "1em", color: "#ab966d" }}>
                        <label htmlFor="password">Password:   </label>
                        <input //attributes below
                            type="password"
                            id="password"
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        /></div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button className="loginButton">Sign In</button>
                </form>
            </div>

        </div>
    )
}
export default Login;

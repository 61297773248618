import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from "../../img/GN.png"
import Logo2 from "../../img/GN_blue.png"
import SNLogo from "../../img/SN_whiteLogo.png"
import SNLogo2 from "../../img/SN_goldLogo.png"

import '../../components/visuals/design/skSpecial.css'
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import hexToRgba from '../../components/visuals/design/hexToRgba';

const Homebase = () => {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };


    return (
        <div>
            <div className="kingdom" >

                {/* SPIRITS NETWORK */}
                <div className="columnPlain" >

                    <img src={theme.palette.mode === 'dark' ? SNLogo2 : SNLogo2} alt="Image 3" style={{ width: '250px', marginBottom: "-30px", marginLeft: '10px' }} />


                    {/* SALES */}

                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <button
                            className="phylum-button"
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                padding: '10px 20px',
                                cursor: 'pointer',
                                color: '#fff',
                                fontSize: '16px',
                                fontWeight: 900,
                                letterSpacing: '1.5px'
                            }}
                            onClick={() => window.location.href = '/snsales'}
                        >
                            Sales
                        </button>

                    </div>

                    {/* MEMBERS */}

                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="h1bsk" style={{ marginBottom: '6px' }}>
                            Members
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/ReachSN')} style={{ background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Quantity
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/Audience')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Quality
                            </button>
                        </div>

                    </div>



                    {/* BRANNDS SN */}
                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="h1bsk" style={{ marginBottom: '6px' }} onClick={() => handleNavigation('/Campaigns')}>
                            Brands
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/CampaignsSN')} style={{ background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Campaigns
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/BrandSalesSN')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Sales
                            </button>
                        </div>
                    </div>

                    {/* CTV  SN*/}
                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="h1bsk" style={{ marginBottom: '6px' }}>
                            CTV
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/VizioSN')} style={{ background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Vizio
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Other
                            </button>
                        </div>
                    </div>

                </div>

                {/* GOLF NATION /////////////////////////////// */}
                <div className="columnPlain" >

                    <img src={theme.palette.mode === 'dark' ? Logo : Logo2} alt="Image 3" style={{ width: '250px', marginBottom: "-30px", marginLeft: '10px' }} />

                    {/* SALES GN */}
                    <div className="phylum" >
                        <div className="h1bsk" style={{ marginBottom: '6px' }}>
                            Sales
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/AnnualGN')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Overview
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/SalesGN')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Itemized
                            </button>
                        </div>
                    </div>

                    {/* MEMBERS GN */}
                    <div className="phylum" >
                        <div className="h1bsk" style={{ marginBottom: '6px' }}>
                            Members
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/ReachGN')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Quantity
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/AudienceGN')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Quality
                            </button>
                        </div>
                    </div>


                    {/* BRNADS GN */}
                    <div className="phylum" >
                        <div className="h1bsk" style={{ marginBottom: '6px' }} onClick={() => handleNavigation('/Campaigns')}>
                            Brands
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/CampaignsGN')} style={{ background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Campaigns
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/BrandSalesGN')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Sales
                            </button>
                        </div>
                    </div>

                    {/* CTV */}
                    <div className="phylum" >
                        <div className="h1bsk" style={{ marginBottom: '6px' }}>
                            CTV
                        </div>
                        <div className="rowCenter" style={{ width: '205px' }}>
                            <button className="phylumButton" onClick={() => handleNavigation('/VizioGN')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                Vizio
                            </button>
                            <button className="phylumButton" onClick={() => handleNavigation('/')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                Other
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Homebase

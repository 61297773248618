import React, { useEffect, useState } from "react";

import { tokens } from '../../../theme';
import { useTheme } from '@mui/material';

const Breakdown = (props) => {
    // console.log('props', props);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //mntn
    // const [mntn, setMntn] = useState(0);
    // useEffect(() => {
    //     const fetchMntn = async () => {

    //         const response = await fetch('https://api.mountain.com/apidata?key=uowowFvBF4RIqXYp55td&begin=2023-12-01&end=2024-01-31&format=json&data=Advertiser.Impressions')

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         const data = await response.json();
    //         // setMntn(data)

    //         // Sum the impressions
    //         const sumOfImpressions = data.data.reduce((acc, item) => {

    //             //impressions must be converted from string to # 
    //             const impressions = Number(item.Impressions.replace(/,/g, ''));
    //             return acc + (isNaN(impressions) ? 0 : impressions);
    //         }, 0);

    //         setMntn(sumOfImpressions);

    //     }
    //     fetchMntn()

    // }, [])

    console.log(props.details)

    return (
        <div className="visibleBorder2" id="lato">

            {/* header  ///////////////////////////// */}
            <div style={{ padding: '10px', justifyContent: "Center", color: colors.SKcustom[10], backgroundColor: "#15616d" }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div style={{ marginRight: '20px' }}>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Client: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.holding}</span> </div>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Launched: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.live}</span> </div>
                    </div>

                    <div>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Campaign: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.campaign}</span> </div>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Brands: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.allBrands}</span> </div>
                    </div>
                </div>
            </div>

            {/* FOOTER - Guranteed */}
            < div className="row" style={{ backgroundColor: "#111522", paddingBottom: "10px", justifyContent: "Center" }}>

                <div style={{ display: 'flex', flexDirection: 'column' }} >

                    <div style={{ color: "#fff", marginTop: '10px', fontSize: "14px", fontWeight: 600, color: "#F6B65C", textAlign: 'center' }}>Campaign Overview </div>

                    <table style={{ alignItems: "top", marginTop: "10px", marginBottom: '10px', borderSpacing: 0, border: 'none' }}>
                        <thead>
                            <tr style={{ color: "#fff" }}>
                                <th className="campcolCenter" style={{ fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px", border: 'none', backgroundColor: 'gray' }}></th>
                                <th className="campcolCenter" style={{ fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px", border: 'none', backgroundColor: 'gray' }}>Imps</th>
                                <th className="campcolCenter" style={{ fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px", border: 'none', backgroundColor: 'gray' }}>View</th>
                                {/* <th className="campcolCenter" style={{ fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px", border: 'none', backgroundColor: 'gray' }}>CTV</th> */}
                                <th className="campcolCenter" style={{ fontWeight: 400, color: "#fff", paddingLeft: "5px", fontSize: ".8em", border: 'none', backgroundColor: 'gray' }}>Emails</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr key={props.details._id} style={{ color: "#fff" }}>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '10px', border: 'none' }}>Guaranteed:</td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>{props.details.imp_min ? props.details.imp_min.toLocaleString() : 'N/A'}</td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>{props.details.view_min ? props.details.view_min.toLocaleString() : 'N/A'}</td>
                                {/* <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>{props.details.ctv_min ? props.details.ctv_min : 'N/A'} </td> */}
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>{props.details.emails}</td>
                            </tr>

                            <tr key={props.details._id} style={{ color: "#fff" }}>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '10px', border: 'none', color: "#F6B65C" }}>Actual:</td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none', color: "#F6B65C" }}>{props.details.Imp_CampaignTotal != null ? props.details.Imp_CampaignTotal.toLocaleString() : "N/A"}</td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none', color: "#F6B65C" }}>{props.details.View_CampaignTotal != null ? props.details.View_CampaignTotal.toLocaleString() : "N/A"}</td>
                                {/* <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none', color: "#F6B65C" }}>{props.details.vizioTotal != null ? props.details.vizioTotal.toLocaleString() : "N/A"}</td> */}
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none', color: "#F6B65C" }}></td>
                            </tr>

                            <tr key={props.details._id} style={{ backgroundColor: "#15616d" }}>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '10px', border: 'none' }}>Difference:</td>

                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>
                                    {props.details.imp_min && props.details.Imp_CampaignTotal != null ?
                                        `${Math.round(((props.details.Imp_CampaignTotal - props.details.imp_min) / props.details.imp_min) * 100).toLocaleString()}%`
                                        : 'N/A'}
                                </td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>
                                    {props.details.view_min && props.details.View_CampaignTotal != null ?
                                        `${Math.round(((props.details.View_CampaignTotal - props.details.view_min) / props.details.view_min) * 100).toLocaleString()}%`
                                        : 'N/A'}
                                </td>
                                {/* <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}>
                                    {props.details.ctv_min && props.details.ctv_min != null ?
                                        `${Math.round(((props.details.ctv_min - props.details.ctv_min) / props.details.ctv_min) * 100).toLocaleString()}%`
                                        : 'N/A'}
                                </td> */}
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '12px', border: 'none' }}></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div >


            {/* body  ///////////////////////////// */}
            <div style={{ display: 'flex', backgroundColor: "#111522", padding: "10px", justifyContent: "Center" }}>

                <div className="campaignColumn">

                    {/*  GoogleAds */}
                    <div style={{ color: "#fff", marginBottom: '0', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Google Ads</div>

                    <div className="campaignData">Imp:
                        <span className="campaignStat">{props.details.g_imp_old ? (props.details.g_imp_old.toLocaleString()) : 'n/a'}</span> </div>

                    <div className="campaignData">Interactions:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Avg CPM:
                        <span className="campaignStat">*</span> </div>

                    {/*  YouTube */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>YouTube</div>

                    <div className="campaignData">Views:
                        <span className="campaignStat">{props.details.YTviews ? (props.details.YTviews.toLocaleString()) : 'n/a'}</span></div>

                    <div className="campaignData">Likes:
                        <span className="campaignStat">{props.details.YTlikes ? (props.details.YTlikes) : 'n/a'}</span></div>

                    {/*  GA4 */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>GA4</div>

                    <div className="campaignData">Page Views:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Users:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Views Per User:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Avg Engagement Time:
                        <span className="campaignStat">*</span></div>
                </div>

                <div className="campaignColumn" style={{ borderLeft: "1px solid", paddingLeft: "15px" }}>
                    {/*  Facebook */}
                    <div style={{ color: "#fff", marginBottom: '0', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Facebook Ads</div>

                    <div className="campaignData" >Imp:
                        <span className="campaignStat">{props.details.meta_imp_old ? props.details.meta_imp_old.toLocaleString() : 'n/a'}</span></div>

                    <div className="campaignData">Views:
                        <span className="campaignStat">{props.details.meta_view_old ? props.details.meta_view_old : 'n/a'}</span></div>

                    {/* Instagram */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Instagram</div>
                    <div className="campaignData">Reach:
                        <span className="campaignStat">{props.details.IGreach ? props.details.IGreach : 'n/a'}</span></div>
                    <div className="campaignData"># of Reels:
                        <span className="campaignStat">{props.details.IGposts ? props.details.IGposts : '0'}</span></div>

                    {/* Klaviyo */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Klaviyo</div>
                    <div className="campaignData">Opens:
                        <span className="campaignStat"></span>{props.details.klaviyoOpen ? props.details.klaviyoOpen.toLocaleString() : 'n/a'}</div>

                    <div className="campaignData">Clicks:
                        <span className="campaignStat"></span>{props.details.klaviyoClick ? props.details.klaviyoClick.toLocaleString() : 'n/a'}</div>

                    <div className="campaignData">Recipients:
                        <span className="campaignStat"></span>{props.details.klaviyoSent ? props.details.klaviyoSent.toLocaleString() : 'n/a'}</div>
                </div>

                <div className="campaignColumn" style={{ borderLeft: "1px solid", paddingLeft: '15px' }}>

                    {/*  CTV */}
                    <div style={{ color: "#fff", marginBottom: '0', fontSize: ".8em", width: "150px", fontWeight: 600 }}>Vizio</div>

                    <div className="campaignData">2024:
                        <span className="campaignStat" style={{ marginLeft: "10px" }}> </span>
                        <span style={{ marginLeft: "5px" }}>{props.details.vizio24min ? props.details.vizio24min.toLocaleString() : 'n/a'}</span>
                    </div>
                    <div className="campaignData">2023:
                        <span className="campaignStat" style={{ marginLeft: "10px" }}> </span>
                        <span style={{ marginLeft: "5px" }}>{props.details.vizio23min ? props.details.vizio23min.toLocaleString() : 'n/a'}</span>
                    </div>




                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "150px", fontWeight: 600 }}>CTV</div>


                    <div className="campaignData">Mntn: <span className="campaignStat">
                        {/* Insert below */}
                        <span className="campaignData"></span> {props.details.mntn ? props.details.mntn.toLocaleString() : 'n/a'}</span></div>


                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Platform</div>
                    <div className="campaignData">Views:  <span className="campaignStat">{props.details.PlatformViews}</span></div>
                    <div className="campaignData">Units Sold:  <span className="campaignStat">*</span></div>


                </div>
            </div >



        </div >

    );


}

export default Breakdown;
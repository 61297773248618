import React, { useState, useEffect } from 'react';

import { tokens } from '../theme'
import { useTheme } from '@mui/material'
import hexToRgba from '../components/visuals/design/hexToRgba'

import '../components/visuals/design/skSpecial.css'

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const SalesSNbeta = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/annualb`);
                if (response.ok) {
                    const json = await response.json();
                    setData(json);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Prepare data for the bar chart

    const categories = Array.from(
        new Set(data.flatMap(entry => entry.categories.map(cat => cat.note)))
    );

    const categoryColors = {
        pantry: "#80ed99",
        spirits: "#81a4cd",
        BOTM: "#edc531",
        whipshots: "#e0aaff",
        engraved: "#3e7cb1",
        ascot24: "#00b4d8",
        dropzone: "#b7094c",
        explorer: "#000"
    };

    const categoryOrder = ["explorer", "BOTM", "spirits", "engraved", "ascot24", "pantry", "dropzone", "whipshots",];

    const orderedCategories = categoryOrder.filter(cat => categories.includes(cat));

    // Create datasets for each category
    const datasets = orderedCategories.map(category => {
        return {
            label: category,
            data: data.map(entry => {
                const categoryData = entry.categories.find(cat => cat.note === category);
                return categoryData ? categoryData.totalGross : 0;
            }),
            backgroundColor: categoryColors[category] || `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.8)` // Default to a random color if not in categoryColors
        };
    });

    const chartData = {
        labels: data.map(entry => `$${entry.combinedTotalGross.toLocaleString()}`),
        datasets
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    boxWidth: 20,

                }
            },

            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const dataset = tooltipItem.dataset;
                        const index = tooltipItem.dataIndex;
                        const note = dataset.label;
                        const category = data[index].categories.find(cat => cat.note === note);

                        if (category) {
                            return `${note}: ${category.percentage}%`;
                        }

                        return `${note}: 0%`;
                    }
                }
            },

            datalabels: {
                display: function (context) {

                    return context.datasetIndex === context.chart.data.datasets.length - 1;
                },
                align: 'top',
                anchor: 'end',
                formatter: function (value, context) {
                    const index = context.dataIndex; // Get the bar group index (year)
                    const combinedTotalGross = data[index]?.combinedTotalGross?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }) || '$0';
                    return combinedTotalGross;
                },
                color: '#000',
                font: {
                    weight: 500,
                    size: 12
                }
            },
        },

        scales: {
            x: {
                stacked: true,
                title: {
                    display: false
                },
                ticks: {
                    callback: function (value) {
                        const year = this.getLabelForValue(value);
                        return `${year}`;
                    },
                    font: {
                        weight: 700,
                        size: 12,
                    },
                    color: '#000'
                },

                categoryPercentage: 0.5,
                barPercentage: 0.5,

                ///this maps year
                labels: data.map(entry => entry._id)
            },
            y: {
                stacked: true,
                title: {
                    display: false,
                },
                ticks: {
                    display: false
                }
            }
        }
    };

    return (

        <div>
            <div style={{ textAlign: 'center', color: colors.SKcustom[10] }} className="h1sk"> SN Store Gross Revenue </div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>

                <div className="annualChart">
                    <Bar
                        data={chartData}
                        options={{
                            ...options,
                            maintainAspectRatio: false
                        }}
                        style={{ padding: 5 }}
                    />
                </div>
            </div>

        </div >




    );
};


export default SalesSNbeta
import { React, useState, useEffect } from 'react';
import '../../components/visuals/design/skSpecial.css'
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

const SNTopSellers = () => {

    console.log('hello sk')

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function HexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }


    const [ascotUnits, setAscotUnits] = useState(0)
    const [ascotRev, setAscotRev] = useState(0)
    const [topAscot, setTopAscot] = useState([])

    const [engravedUnits, setEngravedUnits] = useState(0)
    const [engravedRev, setEngravedRev] = useState(0)
    const [topEngraved, setTopEngraved] = useState([])

    const [pantryUnits, setPantryUnits] = useState(0)
    const [pantryRev, setPantryRev] = useState(0)
    const [topPantry, setTopPantry] = useState([])

    useEffect(() => {

        //ASCOT 2024
        const fetchAscotUnits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/ascotunits`);
            const data = await response.json();

            if (response.ok) {
                setAscotUnits(data)
            }
        }
        const fetchAscotRev = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/ascotrev`);
            const data = await response.json();

            if (response.ok) {
                setAscotRev(data);
            }
        };

        const fetchTopAscot = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/ascot24top`);
            const data = await response.json();

            if (response.ok) {
                setTopAscot(data[0].topProducts);
            }
        };

        //Engraved
        const fetchEngravedUnits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/engraved24units`);
            const data = await response.json();

            if (response.ok) {
                setEngravedUnits(data);
            }
        };
        const fetchEngravedRev = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/engraved24rev`);
            const data = await response.json();

            if (response.ok) {
                setEngravedRev(data);
            }
        };

        const fetchTopEngraved = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/engraved24top`);
            const data = await response.json();

            if (response.ok) {
                setTopEngraved(data[0].topProducts);
            }
        };

        //Pantry
        const fetchPantryUnits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/pantry24units`);
            const data = await response.json();

            if (response.ok) {
                setPantryUnits(data);
            }
        };
        const fetchPantryRev = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/pantry24rev`);
            const data = await response.json();

            if (response.ok) {
                setPantryRev(data);
            }
        };

        const fetchTopPantry = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc2/pantry24top`);
            const data = await response.json();

            if (response.ok) {
                setTopPantry(data[0].topProducts);
            }
        };

        fetchAscotUnits()
        fetchAscotRev();
        fetchTopAscot();

        fetchEngravedUnits()
        fetchEngravedRev();
        fetchTopEngraved();

        fetchPantryUnits();
        fetchPantryRev();
        fetchTopPantry();

    }, [])

    const formattedAscotRev = ascotRev.toLocaleString()
    const formattedEngravedRev = engravedRev.toLocaleString()
    const formattedPantryRev = pantryRev.toLocaleString()

    return (
        <div >
            <div className="centerDiv">
                <div className="h1sk" style={{ color: colors.SKcustom[10], marginBottom: '10px' }}>2024 Highlights</div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    {/* Ascot */}
                    <div className="columnBox" style={{ borderColor: HexToRgba(colors.SKcustom[10], 0.5) }}>
                        <div className="h1sk" style={{ color: colors.SKcustom[10] }}>Ascot Awards</div>
                        <div className="rowStats">
                            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>${formattedAscotRev}</div>
                            <div style={{ backgroundColor: colors.SKcustom[10], width: '1px', margin: '2px 5px 2px 5px' }}></div>
                            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>{ascotUnits} Sold</div>
                        </div>

                        {/* topsellers */}
                        <div className="topHeader">Top Sellers </div>

                        <div style={{ color: colors.SKcustom[10] }}>
                            <ol style={{ paddingLeft: 10 }}>
                                {topAscot.map((item, index) => (
                                    <li key={index}>

                                        <span style={{ fontSize: '0.8em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                            {item.product}
                                        </span> •

                                        <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginRight: "" }}>
                                            {` ${item.totalUnits}`} units
                                        </span>

                                        {/* <span style={{ fontSize: '0.8em' }}>
                                        {` ${item.totalSales}`}</span> */}

                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>

                    {/* Engraved */}
                    <div className="columnBox" style={{ borderColor: HexToRgba(colors.SKcustom[10], 0.5) }}>
                        <div className="h1sk" style={{ color: colors.SKcustom[10] }}>Engraved Bottles</div>
                        <div className="rowStats">
                            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>${formattedEngravedRev}</div>
                            <div style={{ backgroundColor: colors.SKcustom[10], width: '1px', margin: '2px 5px 2px 5px' }}></div>
                            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>{engravedUnits} Sold</div>
                        </div>
                        <div className="topHeader">Top Sellers</div>
                        <div style={{ color: colors.SKcustom[10] }}>
                            <ol style={{ paddingLeft: 10 }}>
                                {topEngraved.map((item, index) => (
                                    <li key={index}>

                                        <span style={{ fontSize: '0.9em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                            {item.product}
                                        </span> •

                                        <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginRight: "" }}>
                                            {` ${item.totalUnits}`} units
                                        </span>

                                        <span style={{ fontSize: '0.8em' }}>
                                            {` ${item.totalSales}`}</span>

                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>


                    {/* Pantry */}
                    <div className="columnBox" style={{ borderColor: HexToRgba(colors.SKcustom[10], 0.5) }}>
                        <div className="h1sk" style={{ color: colors.SKcustom[10] }}>Pantry Sales</div>
                        <div className="rowStats">
                            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>${formattedPantryRev}</div>
                            <div style={{ backgroundColor: colors.SKcustom[10], width: '1px', margin: '2px 5px 2px 5px' }}></div>
                            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>{pantryUnits} Sold</div>
                        </div>
                        <div className="topHeader">Top Sellers</div>
                        <div style={{ color: colors.SKcustom[10] }}>
                            <ol style={{ paddingLeft: 10 }}>
                                {topPantry.map((item, index) => (
                                    <li key={index}>

                                        <span style={{ fontSize: '0.9em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                            {item.product}
                                        </span> •

                                        <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginRight: "" }}>
                                            {` ${item.totalUnits}`} units
                                        </span>

                                        <span style={{ fontSize: '0.8em' }}>
                                            {` ${item.totalSales}`}</span>

                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>

                </div>

            </div >
        </div >)
}

export default SNTopSellers
